#testimonials {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 238, 225, 0.8730085784313726) 100%
  );
  border-radius: 2rem;
  margin: 0 1rem;
  .testimonials {
    background-color: transparent;
    box-shadow: none;

    &__card {
      user-select: none;
      background-color: transparent;
      box-shadow: none;
      border-radius: 1rem;

      &-review {
        color: $color-black;
        font-weight: 300;
        display: block;
        margin: 0 auto 0;
        font-size: 0.85rem;
      }
    }
  }

  // Middle Testimonial
  .middleCard {
    @media screen and (min-width: 768px){
      background-image: linear-gradient(310deg, #F4EEE1, $color-primary);
      box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
    }
  }
}