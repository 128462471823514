// General Styles
.footer {
  &__container {
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
  }

  // Horizontal rule dividing footer from contact form
  &__hr{
    background-color: transparent;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.4),
      transparent
    ) !important;
    border-top: none !important;
    height: 1px !important;
    opacity: 0.25 !important;
    margin: 1rem 0;
  }

  // Social Media Styles
  &__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
    position: relative;

    a {
      // color: var(--color-primary);
      color: $color-primary;
      padding: 0.8rem;
      display: flex;
      font-size: 1.5rem;

      &:hover {
        color: $color-light;
      }

      svg {
        stroke: #868686;
        stroke-width: 8px;
      }

      // Media Queries (Medium Devices)
      @media screen and (min-width: 992px) {
        padding: 0;
        font-size: 1.2rem;
        box-shadow: 3px 2px 10px rgba(0,0,0,0.1);
        border-radius: 50%;
      }
    }
    // Media Queries (Small Devices)
    @media screen and (max-width: 600px) {
      margin-bottom: 2.6rem;
    }
  }

  // Copyright Styles
  &__copyright {
    margin-bottom: 4rem;
    color: #000;
  }
}
