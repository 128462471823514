.navbar-brand {
  max-width: 75px;
}

.navbar {
  transition: all 0.5s;
  padding: 0 10px;

  @media screen and (max-width: 992px) {
    backdrop-filter: saturate(100%) blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.navbar-scrolled {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: #ffffffcc !important;
}

.navbar-collapse li {
  text-align: left;
}

.apply-btn{
  margin-right: -19px;
  font-size: 1.2rem;

  @media screen and (min-width: 992px){
    margin-right: 0;
  }
}
