.about {
  &__container {
    position: relative;
  }

  &__svg {
    width: 95%;
  }

  // Portrait Styles
  &__portrait {
    position: relative;
    max-width: 400px;
    
    &--image{
      border-radius: 1rem;
      box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
      0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid $color-primary;
      bottom: 15px;
      left: 15px;
      z-index: -1;
      border-radius: 1rem;

      @media screen and (min-width: 768px){
        bottom: 20px;
        left: 20px;
      }
    }
  }
}
