@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Poppins:wght@300;400;500;600&family=Quicksand&display=swap");

@import "./variables";
@import "./components/nav/nav";
@import "./components/header/header";
@import "./components/about/about";
@import "./components/details/details";
@import "./components/coaching/coaching";
@import "./components/macros/macros";
@import "./components/testimonials/testimonials";
@import "./components/contact/contact";
@import "./components/footer/footer";
@import "./components/modal/modal";
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  font-size: 1.2rem;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Quicksand", sans-serif;
  color: #333333;
  line-height: 1.7;
  background-color: #ffffff;
  background-attachment: fixed;
  background-size: cover;
}

/* ===================== GENERAL STYLES ===================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

h3 {
  font-size: 1.3rem;
}

section {
  padding: 4rem 0;

  h2,
  h5 {
    text-align: center;
  }

  h2 {
    margin-bottom: 3rem;
  }

}

a {
  color: $color-primary;
}

a:hover {
  color: $color-white;
}

.btn-primary {
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  cursor: pointer;
  background: $color-primary;
  color: $color-black;
  border: 1px solid $color-primary !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 50px;

  &:hover {
    color: rgba(0,0,0,0.5) !important;
    background: $color-primary-variant !important;
  }

  &:active{
    background-color: transparent !important;
  }
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

small {
  font-size: 0.85rem;
}
