$background-gradient: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 1500'%3E...your gradient SVG data...%3C/svg%3E");

#foundation {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 238, 225, 0.8730085784313726) 100%
  );
  border-radius: 2rem;
  margin: 0 1rem;

  .foundation {
    &__container {
      #option1,
      #option2,
      #option3 {
        background-size: cover;
        background-position: center top;
        box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
        height: 60vh;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        border-radius: 1rem;
        overflow: hidden;

        p {
          color: #000;
          z-index: 3;
        }
      }

      #option1 {
        background-image: url("../../assets/headshot-2.jpg");
      }
      #option2 {
        background-image: url("../../assets/headshot-3.jpg");
      }
      #option3 {
        background-image: url("../../assets/headshot-4.jpg");
      }

      // Service Card Styles
      .foundation__card {
        box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
          0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
        border-radius: 1rem;
        backdrop-filter: saturate(200%) blur(30px);
        background-color: #ffffffcc !important;
        z-index: 1;
        width: 90%;
        position: absolute;
        bottom: 0;
        padding-bottom: 2rem;

        @media screen and (min-width: 992px) {
          display: none;
        }
      }
    }
  }
}

.card {
  &__container {
    margin-top: -5rem;
    display: none;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }

  // Service Card Styles
  &__options {
    box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
      0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
    border-radius: 1rem;
    backdrop-filter: saturate(200%) blur(30px);
    background-color: #ffffffcc !important;
    z-index: 1;
  }

  &__option {
    hr {
      position: absolute;
      background-color: transparent;
      height: 100%;
      right: 0;
      top: 0;
      width: 1px;
      background-image: linear-gradient(
        180deg,
        transparent,
        rgba(0, 0, 0, 0.4),
        transparent
      );
    }
  }
}