.modal {
  .modal-dialog {
    .modal-content {
      overflow: hidden;
      background-color: transparent;
      margin: 0 1rem;
    }
    .modal-header {
      border: none;
    }
    .modal-body {
      position: relative;
      .btn-close {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      a {
        color: $color-black;
        padding: 5rem 2rem;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
      }
      :nth-child(2) {
        background-color: $color-white;
        &:hover{
            background-color: #f9e1e1;
            color: #0000002b;
        }
      }
      :nth-child(3) {
        background-color: $color-primary;

        &:hover{
            background-color: $color-primary-variant;
            color: #0000002b;
        }
      }
    }
  }
}
