#contact{
.contact {
  // Form styles
  &__form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    input,
    textarea {
      width: 100%;
      padding: 1.5rem;
      border-radius: 0.5rem;
      background: transparent;
      border: 2px solid $color-primary-variant;
      resize: none;
      color: $color-black;
    }
  }
}
}
